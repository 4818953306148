export const state = () => ({
    dataWheel: [],   //輪盤
    slicesWheel: {}, //輪盤獎項
    haveQualify: [], //有抽獎
    errorCode: [],   // 無資格信息
    wheelStyle: [],  //輪盤調色盤
    slicesStyle: {}, //獎項調色盤
    loading: false
})

export const mutations = {
    setDataWheel: (state, data) => {
        state.dataWheel = data;
    },
    setSliceWheel: (state, data) => {
        state.slicesWheel = data;
    },
    setHaveQualify: (state, data) => {
        state.haveQualify = data;
    },
    setErrorCode: (state, data) => {
        state.errorCode = data;
    },
    setWheelStyle: (state, data) => {
        state.wheelStyle = data;
    },
    setSlicesStyle: (state, data) => {
        state.slicesStyle = data;
    },
    setLoading: (state, data) => {
        state.loading = data;
    }
}

export const actions = {
    async getSlice({ commit }, id) {
        commit('setLoading', true);
        try {
            const response = await this.$axios.get(`/api/RouletteActivity/get/${id}`);
            if (response.status === 'ok' && response.data) {
                const wheelStyle = JSON.parse(response.data.custom_style);
                const sliceStyle = response.data.slices.map((item) => {
                    return item.custom_style ? JSON.parse(item.custom_style) : {}
                });
                
                commit('setErrorCode', []);
                commit('setDataWheel', response.data);
                commit('setSliceWheel', response.data.slices);
                commit('setWheelStyle', wheelStyle);
                commit('setSlicesStyle', sliceStyle);
            } else if (response.status === 'error') {
                commit('setErrorCode', { message: response.msg });
            }
            return response;
        } catch (error) {
            throw new Error('Error during getSlice check: ', error);
        } finally {
            commit('setLoading', false);
        }
    },
    async getQualify({ commit }, id) {
        const account = this.$auth.$state.user.name;
        try {
            const response = await this.$axios.post(`/api/RouletteActivity/roll/${id}`, { account });
            if (response.status === 'ok') {
                commit('setErrorCode',[]);
                commit('setHaveQualify', response);
            } else if (response.status === 'error') {
                if (!response.code) {
                    return;
                } 
                commit('setErrorCode', { message: response.code });
            }
            return response;
        } catch (error) {
            throw new Error('Error during getQualify check: ', error);
        }
    }
}